<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">
          <v-card-actions>

            <v-row align-items="center">
              <v-col class="flex_end">
                <v-btn
                  color="primary"
                  depressed
                  tile
                  @click="add_package()"
                >
                  Dodaj pakiet
                </v-btn>
              </v-col>
            </v-row>

          </v-card-actions>
        </v-card>

        <v-form
          ref="form" 
          v-model="valid"
          lazy-validation
        >
          <v-card>
            <v-card-text>

              <!-- <v-select
                v-model="category"
                :items="list"
                item-text="name"
                item-value="id"
                label="Kategoria pakietu"
                single-line
                :rules="$store.state.rules.not_null"
              ></v-select> -->

              <v-autocomplete
                v-model="category"
                :items="list"
                item-text="name"
                item-value="id"
                no-data-text="Brak wyników"
                label="Kategoria pakietu"
                :rules="$store.state.rules.not_null"
              ></v-autocomplete>

              <v-textarea
                v-model="description"
                label="Opis / Notatka"
                color="primary"
                filled
              ></v-textarea>

              <v-text-field
                v-model="price"
                label="Kwota (netto)"
                color="primary"
                suffix="zł"
                :rules="$store.state.rules.not_null"
              ></v-text-field>

              <v-menu
                v-model="date_picker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="end_data"
                    label="Data wygaśnięcia pakietu"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="end_data"
                  @input="date_picker = false"
                  locale="pl-pl"
                  color="primary"
                ></v-date-picker>
              </v-menu>


            </v-card-text>
          </v-card>
        </v-form>

      </v-col>
    </v-row>
    
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    id: 0,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista Klientów - pakiety',
        disabled: false,
        to: '/pakiety',
      },
      {
        text: 'Lista pakietów Klienta',
        disabled: false,
        to: '/pakiet',
      },
      {
        text: 'Nowy pakiet',
        disabled: true,
        to: '/pakiet-nowy',
      },
    ],

    category: '',
    description: '',
    list: [],
    price: null,
    end_data: new Date().toISOString().substr(0, 10),
    date_picker: false,

  }),
  methods: {
    add_package(){
      if(this.$refs.form.validate()) {
        this.$store.commit('loader');

        let formData = new FormData();
        formData.append("category", this.category);
        formData.append("description", this.description);
        formData.append("price", this.price);
        formData.append("end_data", this.end_data);
        formData.append("assign_user", this.id);

        this.$axios({url: this.$store.state.api +'/package', data: formData, method: 'POST' })
        .then(resp => {
          console.log(resp.data);
          this.$router.push('/pakiet/'+this.id);

          this.$store.commit("snackbar", {
            text: "Dodano pakiet",
            color: "primary",
            btn_color: "white"
          });
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
      }
    },
  },
  mounted(){
    this.id = this.$route.params.id;
    console.log(this.id);
    this.breadcrumb[2].to = '/pakiet/'+this.id;

    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/category_package', data: {}, method: 'GET' })
      .then(resp => {
        this.list = resp.data;
        console.log(resp.data);
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.card_section {
  margin-top: 25px;
}
.checkbox {
  margin-top: 0;
}
</style>